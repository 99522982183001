<template>
    <div>
        <van-nav-bar
            title="路由地址"
            left-text="返回"
            left-arrow
            @click-left="onClickLeft"
        />
        
        <div class="m-10 font-14">
            <div v-for="(item,index) in routing" :key="index" class="flex flex-ai-c flex-jc-sb l-h-40 b-b">
                <div class="flex flex-ai-c" @click="openRouter(item.path)">
                    <div class="m-r-20">{{item.meta.title}}</div>
                    <div :id="'R'+index">{{item.path}}</div>
                </div>
                <div class="text-align-r" style="min-width: 65px;" @click="copy('R'+index)">
                    <van-tag type="success">复制路由</van-tag>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    
import ly from '../router/router.js'
    
export default {
    data () {
        return {
            routing:ly.options.routes
        }
    },
    methods: {
        onClickLeft() {
            this.$router.go(-1);
        },
        openRouter(rou){
            this.$router.push({
                path:rou
            })
        },
        copy(id){
            let val = document.getElementById(id)
            window.getSelection().selectAllChildren(val)
            document.execCommand("Copy"); // 执行浏览器复制命令
            this.$toast('复制成功')
        }
    }
}
</script>

<style>
    .van-tag{
        padding: 3px;
    }
</style>